#top-overlay {
    position: fixed;
    top: 0px;

    width: 100vw;
    height: $topBarHeight;

    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        height: $topBarHeight / 2;
    }

    @media screen and (max-width: #{$logoBP1}) {
        height: 70px;
    }

    z-index: 4;

    background: $gradient;
    box-shadow: $boxShadow;


    #overlay-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        height: 100%;

        padding: 0px 10px;

        @import 'logo';
        @import 'menu';
    }
}

.clickable-phone-banner {
    position: fixed;
    top: 69px;
    width: 100%;
    height: 105px;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(47, 28, 21, 1.0), rgba(47, 28, 21, 0.0));
    align-items: center;

    a{
        color: $SSLeafGreen;
        text-shadow: .0em .10em .15em black;
    }
}

.pad-top {
    padding-top: 10px;
    font-size: 3em;
}