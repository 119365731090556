#footer-wrapper {
    width: 100vw;
    height: $footerHeight;

    background: $gradientBarkBrownDark;

    #footer {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0px 20px;
        margin: 0px auto;
        max-width: 960px;
        height: 100%;

        small {
            color: white;
            text-align: center;

            a {
                color: $SSLeafGreen;
            }
        }
    }
}