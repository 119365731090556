#nav-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 50%;
    height: 100%;

    @import 'menu_icon';

    #menu-icon {
        display: none;
    }

    #site-links {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .siteLink {
            margin: 12px;

            color: $SSBarkBrown;
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            text-decoration: none;
            white-space: nowrap;

            cursor: pointer;

            &:hover {
                color: $SSLeafGreen;
            }
        }
    }

    // At or below 745px wide or 480px tall, 
    // logo decreases so nav menu width needs to be adjusted
    @media screen and(max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        width: calc(100% - (#{$logoSize} / 2));

        #site-links {
            .siteLink {
                margin: 8px;
            }
        }
    }

    // At or below 570px wide, hide the site links and reveal the menu icon button.
    // Site links are revealed via the menu icon button
    @media screen and (max-width: #{$menuIconBP}) {
        position: relative;
        flex-flow: column nowrap;
        // justify-content: space-between;

        // overflow: visible;

        #menu-icon {
            display: flex;
        }

        #site-links {
            position: absolute;
            top: $topBarHeight / 2;

            flex-flow: column nowrap;

            height: 0px;

            overflow: hidden;

            background: rgba(0, 0, 0, .6);
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            .siteLink {
                margin: 10px 10px;
                padding: 5px;
                width: 100px;
                border-radius: 30px;
                background: $gradient;
                box-shadow: $boxShadow;

                &.active {
                    background: $gradientReverse;
                }
            }
        }
    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: #{$logoBP1}) {
        width: calc(100% - 75px);

        #menu-icon {
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px));
        }

        #site-links {
            top: 70px;
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
        }
    }
}