#contact-banner {
    // Contact page banner acts as a background
    position: fixed;
    top: 0px;
    height: 100vh;
    z-index: -1;
}

#contact-content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    margin-top: #{$logoSize};
    padding: 0px 20px;
    width: 100%;

    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        margin-top: #{($logoSize / 2)};
    }

    @media screen and (max-width: #{$logoBP1}) {
        margin-top: 70px; //Height of the top bar
    }

    h1 {
        margin: 20px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 48px;

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            margin: 10px 0px;
            font-size: 32px;
        }
    }

    p {
        margin: 10px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 30px;
        font-weight: bold;

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            font-size: 20px;
        }
    }

    #contact-area {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;

        flex: 50%;
        margin-top: 20px;

        #options-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            padding: 0px 10px;
            width: 50%;

            .option {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;

                margin-bottom: 40px;
                width: 100%;

                a {
                    text-decoration: none;
                    cursor: pointer;

                    img {
                        display: block;
                        margin-right: 20px;

                        width: 100%;
                        height: 100%;

                        max-width: 60px;
                        max-height: 60px;
                    }

                }

                p {
                    margin: 20px 0px;
                    width: 300px;
                    font-size: 24px;
                    font-weight: bold;
                    text-shadow: $textShadow;
                    font-weight: bold;
                    color: white;

                    @media screen and (max-width: 240px) {
                        width: 100%;
                    }
                }

                @media screen and (max-width: #{$logoBP2}),
                screen and (max-height: #{$landscapeBP}) {
                    margin-bottom: 20px;

                    a {
                        img {
                            max-width: 40px;
                            max-height: 40px;
                        }

                        p {
                            margin: 20px 0px;
                            font-size: 20px;
                            width: 250px;
                        }
                    }
                    
                }

                @media screen and (max-width: 699px) {
                    a {
                        img {
                            margin: 0px 20px;
                        }
                    }
                }

                @media screen and (max-width: 580px) {
                    a {
                        width: 100%;

                        img {
                            margin: 0px auto;
                        }

                        p {
                            width: 100%;
                            text-align: center;
                        }

                        #email-prompt {
                            margin-bottom: 0px;
                        }
                    }
                    
                }
            }
        }

        // End #options-container selector

        form {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            padding: 0px 20px;
            width: 50%;

            .inputData {
                margin: 10px 0px;
                padding: 20px;

                width: 100%;
                max-width: 480px;
                height: 40px;

                border: solid 2px white;
                border-radius: $borderRadius;
                background-color: white;
                box-shadow: $boxShadow;

                color: #BBB;
                font-weight: bold;

                &:focus:valid,
                &.filled:valid {
                    border: solid 2px #00DC00;
                    color: #000000;
                }

                &:focus:invalid,
                &.filled:invalid {
                    border: solid 2px red;
                }

            }

            textarea.inputData {
                height: 200px;
                resize: none;
            }

            #submit-button {
                margin: 10px 0px;
                padding: 0px 20px;

                height: 30px;
                line-height: 29px;

                border: unset;
                border-radius: $borderRadius;
                background: $gradient;
                box-shadow: $boxShadow;

                color: $SSBlue;
                font-weight: bold;
                font-size: 16px;

                cursor: pointer;

                &:active {
                    background: $gradientReverse;
                }

                &:disabled {
                    color: #BBB;
                }
            }
        }

        // End form selector

        @media screen and (max-width: 500px) {

            #options-container,
            form {
                width: 100%;
            }

            #options-container {
                justify-content: center;

                .option {
                    p {
                        text-align: center;
                    }
                }
            }
        }

    }

    // End #contact-area

    hr {
        margin: 20px 0px;
        width: 100%;

        color: white;
        box-shadow: $boxShadow;

    }

    #address-area {
        display: flex;
        flex-flow: row wrap;

        margin-bottom: 20px;
        width: 100%;

        p {
            flex: 40%;

            margin: 0px 0px 10px 0px;
        }

        iframe {
            flex: 60%;

            width: 100%;
            height: 480px;

            border: $borderStyle;
            box-shadow: $boxShadow;
        }
    }

}

// End #contact-content-wrapper selector