#services-banner {
    // This banner image that acts as a background to the paragraph section when you load the page
    // Refer to .bannerImage in _base.scss for additional styles
    position: fixed;

    // Set to 120vh because on mobile browsers the screen size changes when you start to scroll
    // and the address bar disappears. This makes no difference on a desktop screen.
    height: 120vh;
    z-index: -1;
}

#services-content-wrapper {
    // Content starts over the banner image, not after it.
    // This wraps the entire page content (introduction & the main sections)
    position: relative;
    top: 0px;

    width: 100%;
    height: 100%;

    z-index: 0;

    #introduction {
        // This is the initial block of elements that appears when the page loads
        // Header & paragraph is included via markdown-it filter 

        margin: 0px auto;
        padding: #{$logoSize + 40px} 20px 0px;

        width: 100%;
        max-width: 960px;
        height: 100%;
        min-height: calc(100vh - 10px);

        color: white;
        text-shadow: $textShadow;


        h1 {
            // "Our Top Notch Services"
            font-size: 48px;
            margin-bottom: 40px;
        }

        p {
            // Introduction paragraph
            font-size: 22px;
            font-weight: bold;
            margin: 20px 0px;
        }

        #explore-button {
            // Explore button, refer to button styles in _base.scss     
            margin: 60px auto 40px;
            padding: 10px 0px;

            width: 100%;
            max-width: 160px;

            font-size: 30px;
        }

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            // Reduce some sizes on smaller screens
            padding-top: #{$logoSize / 2 + 20px};

            h1 {
                font-size: 32px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
            }

            #explore-button {
                margin: 20px auto 20px;

                max-width: 110px;

                font-size: 20px;
            }
        }

        @media screen and (max-width: #{$logoBP1}) {
            // Logo does not overflow menu bar at this point.
            // Only set padding to the menubar height + 20px
            padding-top: 90px;
        }

    } // End #introduction

    // Import styles for the overlay that appears at the bottom of the page
    @import '../components/bottom/overlay';

    #main-content-wrapper {
        // Wrapper for the main content of the page (section buttons & actual sections)

        width: 100%;
        background-color: $TNWhite;

        .seperator {
            // The thick bright green seperators in between each section
            width: 100%;
            height: 20px;
            background-color: $SSLeafGreen;
            border: unset;
        }

        #section-button-container {
            // Container for the page section buttons that appears in the first content section
            // Note I did not nest this in <section> selector in case we want to move it.

            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;

            margin: 20px auto;

            width: 100%;
            max-width: 960px;
            height: 100%;

            .sectionButton {
                // Buttons to navigate to the section on the same page, 
                // refer to button styles in _base.scss

                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                margin: 10px;
                padding: 10px;

                width: 100%;
                max-width: 300px;
                height: 160px;


                img {
                    // The thumbnail image inside the button

                    display: block;
                    width: 100%;
                    max-width: 140px;

                    height: 100%;
                    max-height: 140px;

                    border-radius: 50%;
                    border: $borderStyle;
                    box-shadow: $circleShadow;
                    overflow: hidden;
                }

                span {
                    // The title of the section as it appears in the button

                    margin-left: 10px;
                    width: 100%;
                    max-width: 130px;
                    font-weight: bold;
                    font-size: 22px;
                    text-align: left;

                }
            }

            @media screen and (max-width: #{$logoBP2}),
            screen and (max-height: #{$landscapeBP}) {
                .sectionButton {

                    max-width: 230px;
                    height: 110px;

                    img {
                        max-width: 70px;
                        max-height: 70px;
                    }

                    span {
                        font-size: 18px;
                    }
                }
            }

            @media screen and (max-width: 270px){
                .sectionButton {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        section {
            // Styles for each main content section (navigation buttons, locations, & services)
            margin: 0px auto;
            padding: 40px 0px;

            width: 100%;
            max-width: 960px;

            h2 {
                // Section headers that appears in each section element
                padding: 0px 10px;
                margin: 0px auto 20px;
                font-size: 32px;
                text-align: center;
    
                @media screen and (max-width: #{$menuIconBP}),
                screen and (max-height: #{$landscapeBP}) {
                    font-size: 26px;
                }
    
            }

            h3 {
                font-size: 20px;
            }

            h4 {
                // Any subheaders or prompts
                margin-bottom: 20px;
                padding: 0px 20px;

                text-align: center;
                font-size: 20px;
                color: #737373;
            }

            P {
                // Generic paragraph styles for these sections.

                // By default, markdown-it will wrap all elements within a
                // <p> tag, you can change this but I have chosen to just work around it.
                // Nested in this p tag will be additional styles for elements
                // that have been wrapped by a p tag due to the markdown-it filter
                // applicable to the services page.

                margin: 20px 0px;
                font-size: 20px;

                img {
                    // Full-sized images that appear in each section
                    display: block;

                    margin: 20px 0px 0px;
                    width: 100%;
                    max-width: 960px;
                    height: 100%;
                    max-height: 480px;
                }

                .portfolioLink {
                    // Styling for links that go directly below the section image.
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    text-align: center;
                }

                // Other links are styled via the global <a> tag styling. See _base.scss

            }

            ul {
                display: flex;
                flex-flow: column nowrap;

                padding: 15px;

                box-shadow: $boxShadow;
                border-radius: $borderRadius;

                li {
                    list-style-type: none;
                    // font-weight: bold;
                    margin: 5px 0px;
                    font-size: 18px;
                }

                h2 {
                    .transparent50 {
                        color: rgba(51,56,73,0.5);
                    }
                }

                h3 {
                    font-size: 1.75em;
                }

                p {
                    text-align: center;
                }
            }

            .lessMargins {
                margin: 5px 0px;
            }

            .listHeader {
                font-weight: bold;
                font-size: 18px;
                padding: 0px 15px;
                text-align: center;
            }

            .italicized {
                font-style: italic;
            }

            @media screen and (max-width: 960px) {
                padding: 20px;

                h2, h4 {
                    padding: 0px;
                }
                
            }

            @media screen and (max-width: #{$menuIconBP}),
            screen and (max-height: #{$landscapeBP}) {
                h2 {
                    margin: 10px auto;

                    text-align: left;
                    font-size: 24px;
                }

                h3 {
                    font-size: 16px;
                }

                h4 {
                    margin: 10px auto;
                    font-size: 16px;
                }

                .listHeader {
                    text-align: left;
                    font-size: 16px
                }

                p {
                    font-size: 18px;
                }
            }
        } // end .mainContent

        #county-select {
            // The service locations county option container
            position: relative;

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            width: 100%;
            height: auto;
            min-height: 480px;

            background-color: black;
            border: 5px solid $SSBlue;
            border-radius: $borderRadius;
            box-shadow: $boxShadow;

            overflow: hidden;

            img {
                position: absolute;
                top: 0px;

                width: 100%;
                height: 100%;

                display: block;
                object-fit: cover;
                

                z-index: 0;
            }

            #filter {
                position: absolute;
                top: 0px;

                width: 100%;
                height: 100%;

                background-color: rgba(0, 0, 0, .65);

                z-index: 0;
            }

            button {
                // The county selection buttons
                position: relative;

                padding: 20px 30px;

                width: 100%;

                flex: 1 1 auto;

                background: none;
                border: none;
                border-radius: unset;
                box-shadow: none;

                color: white;
                font-weight: bold;
                font-size: 30px;
                text-align: left;
                text-shadow: $textShadow;

                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, .8);
                }
            }

            hr {
                position: relative;
                margin: 0px;
                padding: 0px;
                width: 100%;
                height: 1px;
                border: none;
                background-color: white;
            }

            ul {
                // The list of cities that appear
                position: absolute;
                bottom: 0px;

                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                align-content: flex-end;

                padding: 20px;

                width: 100%;
                height: calc(100% - 90px);

                opacity: 0;

                pointer-events: none;

                z-index: 1;

                li {
                    margin: 10px;

                    font-size: 20px;
                    font-weight: bold;
                    color: white;
                    text-shadow: $textShadow;
                    list-style: none;
                }

            }

            @media screen and (max-width: #{$menuIconBP}),
            screen and (max-height: #{$landscapeBP}) {

                button {
                    font-size: 24px;
                }

                ul {
                    li {
                        margin: 5px;
                        font-size: 16px;
                    }
                }
            }

            @media screen and (max-width: 430px) {
                button {
                    padding: 10px;
                    font-size: 20px;
                }

                ul {
                    li {
                        font-size: 12px;
                    }
                }
            }
        }

    }
}