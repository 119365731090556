#home-banner {
    height: calc(100vh - 120px);

    #banner-image-overlay {
        position: absolute;
        bottom: 0px;
        z-index: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0px 10px;
        width: 100%;
        min-height: 20%;

        overflow: hidden;

        background-color: rgba(0, 0, 0, 0.4);

        h1 {
            color: $TNWhite;
            text-shadow: 2px 2px 5px black;

            font-size: 86px;
        }
    }

    @media screen and (max-width: #{$menuIconBP}),
    screen and (max-height: #{$landscapeBP}) {
        height: calc(100vh - 110px);
        
        #banner-image-overlay {
            h1 {
                font-size: 56px;
            }
        }
    }
}

#home-content-wrapper {
    #description {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0px 10px;
        height: 100px;

        h2 {
            text-align: center;
            font-size: 32px;
        }

        a {
            color: $SSLeafGreen;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    #video-area {
        padding: 40px;
        width: 100%;

        background-color: $SSMossGrue;

        #video-wrapper {
            margin: 0px auto;

            width: 100%;
            max-width: (960px + ($borderWidth * 2)); // Video Size + Border
            height: 100%; // 540px + borders

            border: $borderStyle;

            video {
                display: block;
                width: 100%;
                outline: none;
            }
        }
    }

    #mission-statement {
        margin: 80px auto;
            .definition-field {
                h1 {
                    text-align: left;
                }
                h2 {
                    text-align: left;
                }
        }
    }

    h2 {
        margin: 20px auto;
        padding: 0px 40px;
        text-align: center;
        font-size: 32px;

        @media screen and (max-width: #{$logoBP2}) {
            font-size: 24px;
        }
    }

    h3 {
        font-size: 1.50em;
        color: $SSBarkBrown;
    }

    p {
        font-size: 24px;
        margin: 20px auto;
        padding: 0px 40px;
    }

    @media screen and (max-width: #{$menuIconBP}),
    screen and (max-height: #{$landscapeBP}) {

        #description {
            h2 {

                font-size: 24px;
            }
        }

        #video-area {
            padding: 20px;
        }

        #mission-statement {
            margin: 40px auto;
        }
    }

    // When the screen gets too narrow, allow the description
    // area height to be flexible.
    @media screen and (max-width: 270px) {
        #description {
            height: 100%;
        }
    }
}