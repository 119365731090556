// ---------- Global Variables ---------- 

// Colors 
$SSBarkBrown: #674f42;
$SSBarkBrownDark: rgb(47, 28, 21);
$SSBarkGrown: #b6aa92;
$SSBarkGrey: #c1bbad;
$SSBlue: #333849;
$SSLeafGreen: #a1c130;
$SSLeafGreenMedium: #4e7407;
$SSMossGrue: #d2dbbe;
$SSMossGrueLight: #f6f8f1;
$TNWhite: #FBFBF8;
$GreenLight: #00dc00;

// Top Overlay 
$topBarHeight: 175px;

$logoSize: 350px;
$logoPadding: 20px;

// widths
$logoBP1: 300px;
$menuIconBP: 570px;
$logoBP2: 745px;

// For short view heights, pairs with logoBP2
// e.g. when you rotate a mobile device
$landscapeBP: 480px;

// Footer 
$footerHeight: 120px;

// General 
$borderWidth: 4px;
$borderStyle: $borderWidth solid $SSBlue;

$borderRadius: 30px;

$gradient: linear-gradient(to bottom, $TNWhite, $SSMossGrueLight);
$gradientReverse: linear-gradient(to top, $TNWhite, $SSMossGrueLight);
$gradientBarkBrownDark: linear-gradient(to top, #160d0a, $SSBarkBrownDark);
$gradientBlack: linear-gradient(to top, black, #1F1F1F);

$boxShadow: 1px 2px 4px 0px rgba(0, 0, 0, .2),
0px 2px 4px 0px rgba(0, 0, 0, .2),
-1px 2px 4px 0px rgba(0, 0, 0, .2);
$circleShadow: 0px -1px 4px 0px rgba(0, 0, 0, .2),
1px 0px 4px 0px rgba(0, 0, 0, .2),
0px 1px 4px 0px rgba(0, 0, 0, .2),
-1px 0px 4px 0px rgba(0, 0, 0, .2);
$textShadow: -1px 2px 4px rgba(0, 0, 0, .3),
0px 1px 4px rgba(0, 0, 0, .3),
1px 2px 4px rgba(0, 0, 0, .3);

// ---------- Base Styles ---------- 
// Reset 
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }

    font-family: 'Roboto Condensed',
    sans-serif;

    // Remove the blue overlay when tapping on links in mobile browsers
    // This is a non-standard property but it seems to work for most commonly used browsers.
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; 
}

// General 
body {
    overflow-x: hidden;

    width: 100vw;
    height: 100%;

    background-color: $TNWhite;
    color: $SSBarkGrown;
}

button {
    // Generic styles for buttons
    display: block;
    border: none;
    background: $gradient;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;

    text-decoration: none;
    color: $SSBarkBrown;
    font-weight: bold;
    text-align: center;

    cursor: pointer;

    &:active {
        background: $gradientReverse;
    }
}

a {
    // Generic styles for links
    text-decoration: none;
    color: $SSLeafGreenMedium;
    font-weight: bold;
}


#body-content {
    height: 100%;
    min-height: calc(100vh - #{$footerHeight});
}

.fixedCenter {
    margin: 0px auto;
    width: 100%;
    max-width: 960px;
}

h1 {
    text-align: center;
}

h2 {
    color: $SSBlue;
}

h3 {
    text-align: center;
}

.bannerImageWrapper {
    position: relative; // position is fixed (so it replicates a background) on the contact page

    display: block;
    width: 100%;
    height: 100%;

    overflow: hidden;

    img {
        // Image will retain its dimensions and be displaced into the center when the
        // screen starts to get smaller, the outer div acts as a box that will
        // crop the image so it doesnt cover the whole screen.
        position: absolute;
        margin-left: calc(-960px + (100vw / 2));

        display: block;
        width: 1920px;
        height: 1010px;
    }

    #filter {
        // Applies a filter over the background image to make it darker (not used on home/index page)
        position: absolute;
        top: 0px;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }
}