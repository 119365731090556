#crew-banner {
    // Crew page banner acts as a background
    position: fixed;
    top: 0px;
    height: 100vh;
    z-index: -1;
}

#crew-content-wrapper {

    margin: 0px auto;
    width: 100%;
    max-width: 960px;
    height: 100%;

    padding: #{$logoSize + 20px} 20px 0px;

    #inner-page-links {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        button {
            margin: 10px;
            padding: 10px;
            font-size: 24px;
            width: 100%;
            max-width: 130px;
        }
    }

    @import '../components/bottom/overlay';

    section {
        display: flex;
        flex-flow: column nowrap;

        margin: 20px 0px;
        padding: 10px;

        width: 100%;
        height: 100%;

        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        background: $TNWhite;

        h1 {
            font-size: 48px;
            margin: 20px 10px;

        }

        h2 {
            font-size: 36px;
            margin: 20px 10px 0px;
        }

        h3 {
            font-size: 24px;
            margin: 0px 10px 0px;
        }

        hr {
            height: 2px;
            background-color: $SSLeafGreen;
            border: none;
            margin: 0px 10px;
        }

        P {
            // Generic paragraph styles for these sections.

            // By default, markdown-it will wrap all elements within a
            // <p> tag, you can change this but I have chosen to just work around it.
            // Nested in this p tag will be additional styles for elements
            // that have been wrapped by a p tag due to the markdown-it filter
            // applicable to the services page.

            margin: 20px 10px;

            height: 100%;

            font-size: 22px;
            font-weight: bold;
        }

        img {
            display: block;
            margin: 10px auto 0px;
            width: 100%;
            border: $borderStyle;
        }

        .lessMarginBottom {
            margin: 20px 10px 10px;
        }

        .specialtyButton {
            display: block;

            margin: 10px 0px;
            padding: 10px;

            height: 100px;
            line-height: 80px;

            border-radius: $borderRadius;
            box-shadow: $boxShadow;
            background: $gradient;

            text-align: center;

            cursor: pointer;

            &:active {
                background: $gradientReverse;
            }
        }

        .specialtyBlurb {
            img {
                margin: 10px 0px;
            }
            margin: 0px 10px;
            font-size: 18px;

            height: 0px;
            overflow: hidden;
        }

        ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            height: 0px;
            overflow: hidden;

            li {
                // display: 

                margin: 10px;
                padding: 10px;

                list-style: none;

                font-weight: bold;


                width: auto;
                height: auto;

                background-color: $TNWhite;
                box-shadow: $boxShadow;
                border-radius: $borderRadius;
            }
        }

    } // End section


    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        padding-top: #{$logoSize / 2 + 20px};

        #inner-page-links {
            button {
                margin: 5px;
                // padding: 5px;
                font-size: 18px;
                max-width: 100px;
            }
        }

        section {
            h1 {
                font-size: 32px;
                text-align: left;
            }
    
            h2 {
                font-size: 22px;
            }
    
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: #{$logoBP1}) {
        padding-top: 90px;

        section {
            h1 {
                font-size: 24px;
                text-align: left;
            }
    
            h2 {
                font-size: 20px;
            }
    
            h3 {
                font-size: 16px;
            }

    

            p {
                font-size: 14px;
            }
        }
    }
} // End crew-content-wrapper