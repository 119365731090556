#logo-wrapper {
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 50%;

    #logo {
        position: absolute;
        top: 0px;
        margin-left: calc(50% - (#{$logoSize} / 2));

        cursor: pointer;
        overflow: hidden; //Chop off the edges around the logo image so it becomes a circle

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .large {
        background-color: white;

        padding: 0px;
        width: $logoSize;
        height: $logoSize;

        border: $borderStyle;
        border-radius: 50%;
        box-shadow: $circleShadow;
    }

    .small {
        width: 70px;
        height: 70px;
    }

    // At or below 745px wide or 480px tall, 
    // logo size is reduced to half
    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        
        width: $logoSize / 2;

        #logo {
            margin-left: 0px;
        }

        .large {
            padding: $logoPadding / 2;
            width: $logoSize / 2;
            height: $logoSize / 2;
        }
    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: #{$logoBP1}) {
        width: 75px;
    }
}